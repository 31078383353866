<template>
  <!-- :for="value" -->
  <div :class="this.classAdd">
    <label class="text-sm font-proximaMedium" :for="value">{{ label }}</label>
    <input
      type="text"
      :disabled="disabled"
      :id="value"
      :placeholder="disabled ? '-' : placeholder ? placeholder : label"
      :value="value"
      :class="inputClass"
      class="w-full rounded-sm px-3 py-3 my-1 text-sm"
      @input="$emit('update', $event.target.value)"
    />

    <FormError :errors="error" />
  </div>
</template>
<script>
import FormError from "../warning/FormError.vue";
export default {
  props: {
    value: String,
    label: String,
    placeholder: String,
    classAdd: String,
    error: String,
    inputClass: {},
    disabled: Boolean,
  },
  components: {
    FormError,
  },
  computed: {
    classNames() {
      let classNames = [];
      classNames.push(`${this.classAdd}`);
      return classNames;
    },
  },

  model: {
    prop: "value",
    event: "update",
  },
};
</script>
