<template>
  <div
    class="w-11/12 md:w-10/12 flex flex-wrap items-start md:items-center justify-between font-proximaMedium"
  >
    <div class="mb-4 w-full block md:hidden">
      <div class="relative">
        <input
          class="w-full py-3 px-4 rounded-md"
          type="text"
          placeholder="Search"
          v-model="searchWord"
          @keyup.enter="searchMethod"
        />
        <img
          class="h-4 absolute top-4 right-2"
          src="@/assets/images/search-icon.png"
          alt="search-icon"
        />
      </div>
    </div>
    <div
      class="w-44 md:w-2/12 py-3 px-6 flex items-center justify-between bg-white rounded-md cursor-pointer"
    >
      <input class="w-10/12 md:w-8/12" type="text" placeholder="Postcode" />
      <LocationIcon
        class="ml-4 mt-1"
        iconColor="#0AC0CB"
        heigth="18"
        width="18"
      />
    </div>
    <div class="md:w-5/12 lg:w-5/12 hidden md:block">
      <div class="relative">
        <input
          class="w-full py-3 px-4 rounded-md"
          type="text"
          placeholder="Search"
          v-model="searchWord"
          @keyup.enter="searchMethod"
        />
        <img
          class="h-4 absolute top-4 right-2"
          src="@/assets/images/search-icon.png"
          alt="search-icon"
        />
      </div>
    </div>
    <div
      class="w-44 md:w-max py-3 px-6 flex items-center justify-between bg-primary text-white rounded-md cursor-pointer"
      @click="openSuggestModals"
    >
      <span>Suggest Service</span>
      <span class="pl-2 font-proximaMedium font-bold">+</span>
    </div>
    <div
      class="w-44 md:w-max py-3 px-6 flex items-center justify-between bg-primary text-white rounded-md cursor-pointer"
      @click="openFavoriteModals"
    >
      <span>Favorite</span>
      <HeartIcon
        class="ml-4 mt-1"
        :iconColor="favoriteComp"
        heigth="18"
        width="18"
      />
    </div>

    <!-- suggest modals -->
    <ModalsConfirm :show="suggestModalsComp">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-10 text-3xl font-proximaMedium cursor-pointer"
          @click="closeSuggestModals"
        >
          ✖
        </div>
        <h1 class="text-2xl">
          {{ $t("service_directoty_suggest_services_title") }}
        </h1>
        <FormError :errors="errorForm" />
        <span class="font-proximaLight text-sm"
          >{{ $t("service_directoty_suggest_services_texte_1") }}
          {{ $t("service_directoty_suggest_services_texte_2") }}
        </span>
      </div>
      <div slot="actionText" class="mt-6">
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            :label="$t('account_business_name')"
            :placeholder="$t('account_business_name')"
            v-model="form.businessName"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
            :error="errors.businessName"
          />
          <!-- <InputForm
            :label="$t('pet_record_secondary_id')"
            placeholder="Maximum 25 characters"
            v-model="category"
            classAdd="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          /> -->
          <div
            class="w-full text-mediumGrey mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
          >
            <!-- w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm -->
            <label class="text-sm font-proximaMedium" for="origin">{{
              $t("service_directoty_service_category") + "*"
            }}</label>
            <select
              class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm bg-lightGrey20"
              v-model="serviceTypeObject"
            >
              <option value="" disabled selected>
                {{ $t("service_directoty_select_category") }}
              </option>
              <option
                v-for="item in serviceTypesList"
                :style="{ 'background-color': `${item.color}` }"
                :value="item"
                :key="item.id + item.name"
              >
                {{ item.name }}
              </option>
            </select>
            <FormError :errors="errors.serviceType" />
          </div>
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            :label="$t('global_phone_number') + '*'"
            :placeholder="$t('global_phone_number')"
            v-model="form.phoneNo"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
            :error="errors.phoneNo"
          />
          <InputForm
            :label="$t('global_email_address') + '*'"
            placeholder="email@email.com"
            v-model="form.emailAddress"
            classAdd="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
            :error="errors.emailAddress"
          />
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            :label="$t('account_website')"
            placeholder=""
            v-model="form.website"
            classAdd="w-full mt-4 md:mt-0 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            :label="$t('global_street_address') + '*'"
            placeholder=""
            v-model="form.streetAddress"
            classAdd="w-full mt-4 md:mt-0 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
            :error="errors.streetAddress"
          />
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            :label="$t('global_postal_code') + '*'"
            placeholder=""
            v-model="form.postalCode"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
            :error="errors.postalCode"
          />
          <InputForm
            :label="$t('global_city') + '*'"
            placeholder=""
            v-model="form.city"
            classAdd="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
            :error="errors.city"
          />
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <div class="w-full ml-1 flex flex-col justify-start items-start">
            <label class="text-sm font-proximaMedium" for="origin">{{
              $t("global_country") + "*"
            }}</label>
            <select
              class="w-full text-mediumGrey rounded-sm px-3 py-3 my-1 text-sm bg-lightGrey20"
              v-model="form.country"
              @change="inputSelectCountry()"
            >
              <option class="text-lightGrey" value="" disabled selected>
                {{ $t("global_select_country") }}
              </option>
              <option
                v-for="item in countriesList"
                :value="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <FormError :errors="errors.country" />
          </div>
          <div
            class="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
          >
            <label class="text-sm font-proximaMedium" for="state">{{
              $t("global_state") + "*"
            }}</label>
            <select
              class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm bg-lightGrey20"
              v-model="form.state"
            >
              <option value="" disabled selected>
                {{ $t("global_select_state") }}
              </option>
              <option
                v-for="item in statesList"
                :value="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <FormError :errors="errors.state" />
          </div>

          <!--  <InputForm
            label="State*"
            placeholder=""
            v-model="state"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          /> -->
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <div class="w-6/12 flex justify-start">
            <input
              type="checkbox"
              v-model="form.english_multilang"
              class="mr-2"
            />
            <span>{{ $t("service_directoty_filter_section_1") }}</span>
          </div>
          <div class="mt-4 md:mt-0 w-6/12 flex justify-start">
            <input type="checkbox" v-model="form.service_24_7" class="mr-2" />
            <span>{{ $t("service_directoty_filter_section_2") }}</span>
          </div>
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <div class="w-6/12 flex justify-start">
            <input
              type="checkbox"
              v-model="form.accept_credit_card"
              class="mr-2"
            />
            <span>{{ $t("service_directoty_filter_section_3") }}</span>
          </div>
          <div class="mt-4 md:mt-0 w-6/12 flex justify-start">
            <input
              type="checkbox"
              v-model="form.special_equipment"
              class="mr-2"
            />
            <span>{{ $t("service_directoty_filter_section_4") }}</span>
          </div>
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <div class="w-6/12 flex justify-start">
            <input
              type="checkbox"
              v-model="form.deferred_payment"
              class="mr-2"
            />
            <span>{{ $t("service_directoty_filter_section_5") }}</span>
          </div>
          <div class="mt-4 md:mt-0 w-6/12 flex justify-start">
            <input
              type="checkbox"
              v-model="form.loyalty_program"
              class="mr-2"
            />
            <span>{{ $t("service_directoty_filter_section_6") }}</span>
          </div>
        </div>
      </div>
      <div slot="leftBtn">
        <BorderedButton
          class="mr-1"
          :title="$t('global_cancel')"
          borderColor="border-darkGrey"
          textCl="text-darkGrey"
          paddingX="px-8"
          width="w-full md:w-10/12"
          @click.native="closeSuggestModals"
        />
      </div>
      <div slot="rightBtn">
        <SubmitButton
          @click.native="submitsuggestServiceProvider"
          class="ml-1"
          title="Confirm"
          background="bg-success"
          paddingX="px-8"
          width="w-full md:w-10/12"
        />
      </div>
    </ModalsConfirm>
    <!-- suggest modals end -->

    <!-- favorite modals -->
    <ModalsConfirm :show="favoriteModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-10 text-3xl font-proximaMedium cursor-pointer"
          @click="favoriteModals = false"
        >
          ✖
        </div>
        <h1 class="text-2xl">Favorite Services</h1>
      </div>
      <div slot="actionText" class="mt-6">
        <div
          class="mb-4 flex items-center justify-between"
          v-for="(data, i) in favouriteServices"
          :key="i"
        >
          <div class="flex items-center">
            <img :src="require('@/assets/images/pp-3.png')" alt="image" />
            <div class="ml-2 text-left">
              <h5 class="font-proximaMedium text-primary text-base">
                {{ data.favouriteServiceEmailAddress }}
              </h5>
              <!-- <span>{{ data.address }}</span> -->
            </div>
          </div>
          <img src="@/assets/images/close-icon.png" alt="close-icon" />
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- favorite modals end -->
  </div>
</template>

<script>
import HeartIcon from "../../icons/HeartIcon.vue";
import LocationIcon from "../../icons/LocationIcon.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import { suggestServiceProvider } from "../../../services/serviceDirectoryService";
import InputForm from "../../forms/InputForm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import FormError from "../../warning/FormError.vue";

import { getCountries } from "../../../services/countriesServices";
import { getStates } from "../../../services/stateServices";
import { getbusinessTypes } from "../../../services/serviceDirectoryService";
import serviceCategories from "../../../locales/serviceCategories.json";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeartIcon,
    LocationIcon,
    ModalsConfirm,
    InputForm,
    SubmitButton,
    BorderedButton,
    FormError,
  },
  data() {
    return {
      form: {
        streetAddress: "",
        country: "",
        state: "",
        city: "",
        postalCode: "",
        emailAddress: "",
        phoneNo: "",
        website: "",
        serviceType: "",
        businessName: "",
        service_24_7: false,
        english_multilang: false,
        accept_credit_card: false,
        special_equipment: false,
        deferred_payment: false,
        loyalty_program: false,
        userEmailAddress: localStorage.getItem("email"),
      },
      serviceTypeObject: {},
      searchWord: "",
      isFavorite: true,
      favoriteModals: false,
      countriesList: [],
      statesList: [],
      serviceTypesList: [],
      category: "",
      errors: {},

      serviceCategoriesJson: serviceCategories,
    };
  },
  computed: {
    ...mapGetters({
      serviceDirectory: "servicedirectory/getServiceDirectories",
      favouriteServices: "contact/getFavouriteServices",
    }),

    errorForm() {
      return this.$store.state.errorForm;
    },
    favoriteComp() {
      if (this.isFavorite) {
        return "#ff0c00";
      } else {
        return "#fff";
      }
    },
    suggestModalsComp() {
      return this.$store.state.suggestModalsState;
    },
  },

  async mounted() {
    await getbusinessTypes().then((result) => {
      let dataVet = result.data.business_vet.map((e) => {
        e.color = "#e9feff";
        e.type = 5; //vet
        return e;
      });
      this.serviceTypesList = this.serviceTypesList.concat(dataVet);

      let dataService = result.data.business_service_provider.map((e) => {
        e.color = "#fff";
        e.type = 4; //SP
        return e;
      });
      this.serviceTypesList = this.serviceTypesList.concat(dataService);
    });

    const a = await getCountries();
    this.countriesList = a.data;

    if (this.form.country) {
      const c = await getStates(this.form.country);
      this.statesList = c.data;
    }

    this.getFavouriteServices(localStorage.getItem("email"));
  },

  methods: {
    ...mapActions({
      getServiceByBusinessName: "servicedirectory/getServiceByBusinessName",
      getFavouriteServices: "contact/getFavouriteServices",
    }),

    openFavoriteModals() {
      if (this.isFavorite) {
        this.favoriteModals = true;
      }
    },
    async inputSelectCountry() {
      const a = await getStates(this.form.country);
      this.statesList = a.data;
    },
    async searchMethod() {
      this.getServiceByBusinessName(this.searchWord);
      console.log(this.serviceDirectory);
      /*  this.$router.push({
        name: "ServiceSearchResults",
        params: { data: this.serviceDirectory },
      }); */

      //this.$emit("resultSearch", result);
    },
    openSuggestModals() {
      this.$store.commit("suggestModals", true);
    },
    closeSuggestModals() {
      this.$store.commit("suggestModals", false);
      this.$store.commit("errorForm", "");
      this.$store.commit("error", "");

      this.form.streetAddress = "";
      this.form.country = "";
      this.form.state = "";
      this.form.city = "";
      this.form.postalCode = "";
      this.form.emailAddress = "";
      this.form.phoneNo = "";
      this.form.website = "";
      this.form.serviceType = "";
      this.form.businessName = "";
      this.form.service_24_7 = 0;
      this.form.english_multilang = 0;
      this.form.accept_credit_card = 0;
      this.form.special_equipment = 0;
      this.form.deferred_payment = 0;
      this.form.loyalty_program = 0;
      this.serviceTypeObject = {};
    },

    validate() {
      const errors = {};

      if (this.form.businessName.trim() === "")
        errors.businessName = this.$t("account_error_business_name");
      if (!this.serviceTypeObject.id)
        errors.serviceType = this.$t(
          "service_directoty_error_service_category"
        );
      if (this.form.phoneNo.trim() === "")
        errors.phoneNo = this.$t("global_error_phone_number");
      if (this.form.emailAddress.trim() === "")
        errors.emailAddress = this.$t("global_error_email_address");

      if (this.form.streetAddress.trim() === "")
        errors.streetAddress = this.$t("global_error_street_address");
      if (this.form.postalCode.trim() === "")
        errors.postalCode = this.$t("global_error_postal_code");
      if (this.form.city.trim() === "")
        errors.city = this.$t("global_error_city");

      if (this.form.country === "")
        errors.country = this.$t("global_error_country");
      if (this.form.state === "") errors.state = this.$t("global_error_state");

      return Object.keys(errors).length === 0 ? null : errors;
    },

    async submitsuggestServiceProvider() {
      const errors = this.validate();
      this.errors = errors || {};
      if (errors) return;

      console.log(this.serviceTypeObject);
      this.form.serviceType = this.serviceTypeObject.id;
      await suggestServiceProvider(this.form, this.serviceTypeObject.type)
        .then(() => {
          this.$store.commit("suggestModals", false);
        })
        .catch((error) => {
          if (error.response.status == 403) {
            this.$store.commit("errorForm", error.response.data.message);
          } else {
            this.$store.commit("errorForm", "API internal error");
          }
        });
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: absolute;
}
</style>
