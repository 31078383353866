<template>
  <div
    class="w-11/12 md:w-10/12 mt-4 flex items-start justify-between flex-wrap"
  >
    <div
      class="w-44 md:w-56 bg-primary-20 p-4 mt-4 font-proximaMedium cursor-pointer"
      v-for="(service, i) in serviceList"
      :key="i"
      @click="goTo(service, i)"
    >
      <img
        class="pb-2"
        :src="
          require('@/assets/images/servicedirectoryicons/' +
            service.image +
            '.png')
        "
        :alt="service.image"
      />
      <span>{{ service.title }}</span>
    </div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
    <div class="w-44 md:w-56"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      serviceList: [
        { title: "Vets & Health Services", image: "Vets-Health-Services" },
        { title: "Behaviourists & Trainers", image: "Behaviourist-Trainers" },
        { title: "Grooming Saloons", image: "Grooming-Saloons" },
        { title: "Hotels & Day Care", image: "Hotels-Day-Care" },
        { title: "Artist Services", image: "Artist-Services" },
        { title: "Insurance Providers", image: "Insurance-Providers" },
        { title: "Sitters & Walkers", image: "Sitters-Walkers" },
        { title: "Funerary Services", image: "Funerary-Services" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      serviceDirectory: "servicedirectory/getServiceDirectories",
    }),
  },
  methods: {
    ...mapActions({
      getServiceDirectoryType1: "servicedirectory/getServiceDirectoryType1",
      getServiceDirectoryType2: "servicedirectory/getServiceDirectoryType2",
      getServiceDirectoryType3: "servicedirectory/getServiceDirectoryType3",
      getServiceDirectoryType4: "servicedirectory/getServiceDirectoryType4",
      getServiceDirectoryType6: "servicedirectory/getServiceDirectoryType6",
      getServiceDirectoryType8: "servicedirectory/getServiceDirectoryType8",
    }),
    goTo(service, i) {
      console.log(service, i);
      const j = i + 1;
      if (j == 1) {
        this.getServiceDirectoryType1();
      }
      if (j == 2) {
        this.getServiceDirectoryType2();
      }
      if (j == 3) {
        this.getServiceDirectoryType3();
      }
      if (j == 4) {
        this.getServiceDirectoryType4();
      }
      if (j == 6) {
        this.getServiceDirectoryType6();
      }
      if (i == 8) {
        this.getServiceDirectoryType8();
      }
    },
  },
};
</script>

<style></style>
